import { useEffect, useState, useRef } from 'react';
import Nav from './components/Nav';
import { COASTS, COASTS_TO_FULL_NAMES, DEFAULT_NUM_PER_PAGE, LATEST_YEAR_FOR_HOUSES, VENUE_NAMES_TO_IDS, PREVIEW_YEAR, DESC_SORT_ORDER_TITLE, ASC_SORT_ORDER_TITLE, VENUE_ID_TO_NAMES, IP_VALS } from './utils/constants';
import { getBackendUrl, range } from './utils/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './Rankings.module.scss';
import Footer from './components/Footer';
import SEO from './components/SEO';
import HouseListItem from './components/HouseListItem';

function Rankings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [houses, setHouses] = useState([]);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const page = searchParams.get("page") && parseInt(searchParams.get("page")) ? parseInt(searchParams.get("page")) : 1;
  const preview = searchParams.get("preview") && searchParams.get("preview") === "true";
  const navigate = useNavigate();

  // See the note for shouldFetchMatchup in App.js.
  const shouldFetchRankings = useRef(true);

  const areFiltersEmpty = () => {
    return !searchParams.has('year') && !searchParams.has('coast') && !searchParams.has('venue') && !searchParams.has('ip');
  }

  const goToConfig = (field, value) => {
    const params = new URLSearchParams();
    params.set(field, value);
    shouldFetchRankings.current = true;
    navigate("/rankings?" + params.toString());
  }

  const goToPage = (e, page) => {
    e.preventDefault();

    const params = new URLSearchParams(searchParams.toString());
    params.set("page", page);
    shouldFetchRankings.current = true;
    navigate("/rankings?" + params.toString());

    return false;
  }

  const handleFilterChanges = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    setHouses([]);

    const copiedParams = new URLSearchParams(searchParams.toString());
    if (value !== searchParams.get(field)) {
      if (value === "unknown") {
        copiedParams.delete(field);
      } else {
        copiedParams.set(field, value);
      }
      // TODO: Add a test here.
      // If you ever change the filter, it should rewind you to page 1.
      copiedParams.delete("page");
    }
    if (copiedParams !== searchParams) {
      shouldFetchRankings.current = true;
      setSearchParams(copiedParams);
      loadHouses(copiedParams);
    }
  }

  // If we can't handle an incoming param, we just delete it
  const sanitizeSearchParams = () => {
    const copiedParams = new URLSearchParams(searchParams.toString());
    const coastParam = copiedParams.get("coast");
    if (coastParam) {
      if (!COASTS.includes(coastParam)) {
        copiedParams.delete("coast");
      }
    }

    const yearParam = copiedParams.get("year");
    if (yearParam) {
      if (!isNaN(parseInt(yearParam))) {
        const yearParsed = parseInt(yearParam);
        if (yearParsed < 1991 || yearParsed > Math.max(PREVIEW_YEAR, LATEST_YEAR_FOR_HOUSES)) {
          copiedParams.delete("year");
        }
      } else {
        copiedParams.delete("year")
      }
    }

    const venueParam = copiedParams.get("venue");
    // TODO: Update this is the maximum venue id changes.
    if (venueParam) {
      if (!isNaN(parseInt(venueParam))) {
        const venueParsed = parseInt(venueParam);
        if (venueParsed < 0 || venueParsed > 77) {
          copiedParams.delete("venue");
        }
      } else {
        copiedParams.delete("venue");
      }
    }

    const ipParam = copiedParams.get("ip");
    if (ipParam) {
      if (!IP_VALS.includes(ipParam)) {
        copiedParams.delete("ip");
      }
    }

    const pageParam = copiedParams.get("page");
    if (pageParam) {
      if (!isNaN(parseInt(pageParam))) {
        const pageParsed = parseInt(page);
        if (pageParsed < 1 || pageParsed > 30) {
          copiedParams.delete("page");
        }
      } else {
        copiedParams.delete("page");
      }
    }

    const sortParam = copiedParams.get("sort");
    if (sortParam) {
      if (!["desc", "asc"].includes(sortParam)) {
        copiedParams.delete("sort");
      }
    }

    const previewParam = copiedParams.get("preview");
    if (previewParam) {
      if (!["true"].includes(previewParam)) {
        copiedParams.delete("preview");
      }
    }

    if (copiedParams.toString() !== searchParams.toString()) {
      setSearchParams(copiedParams);
    }
    return copiedParams;
  };

  const loadHouses = async (params) => {
    if (!shouldFetchRankings.current) {
      return;
    }

    // Fetch started. No more fetches should happen.
    shouldFetchRankings.current = false;
    setLoading(true);

    let queryString = params.toString();
    try {
      const response = await (await fetch(getBackendUrl() + 'ranks?' + queryString)).json();
      // TODO: Check for 200 from the backend here.
      setHouses(response["houses"]);
      setCount(response["count"]);
      setLoading(false);
    } catch (e) {
      shouldFetchRankings.current = true;
      setLoading(false);
    }
  }

  useEffect(() => {
    // If the params change, then we're okay to fetch the page again.
    shouldFetchRankings.current = true;
    loadHouses(sanitizeSearchParams());
  }, [searchParams]);

  const filtersEmpty = areFiltersEmpty();
  let titleString = "Best Halloween Horror Nights " + (searchParams.get("coast") ? COASTS_TO_FULL_NAMES.get(searchParams.get("coast")) : "") + " Houses";
  if (searchParams.get("year")) {
    titleString += (" From " + searchParams.get("year"));
  }
  if (searchParams.get("venue")) {
    titleString += (" In " + VENUE_ID_TO_NAMES.get(parseInt(searchParams.get("venue"))));
  }
  if (searchParams.get("ip")) {
    titleString += (" That are " + (searchParams.get("venue") === "true" ? "IP" : "Original") + " Houses")
  }
  let pageString = "";
  if (searchParams.get("page")) {
    pageString += (" | Page " + searchParams.get("page"));
  }
  return (
    <div className={styles.body}>
      <Nav />
      <SEO
        title={titleString + pageString + " | HHNH2H"}
        description={"Find the " + titleString + ". HHNH2H uses a cutting edge sorting algorithm to use YOUR votes to determine the best houses ever at Halloween Horror Nights. Check and see whether your favorite house made the cut!"}
      />
      <div className={'container is-fluid mb-3 ' + styles.main}>
        <button className='button is-primary is-active is-hidden-desktop is-fullwidth mb-3' onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? "Hide Filters" : "Show Filters"}
        </button>
        <div className={'field is-horizontal mb-6 ' + (showFilters ? "" : "is-hidden-touch")}>
          <div className='field-label is-normal'>
            <label className='label'>Coast</label>
          </div>
          <div className='field-body'>
            <div className='field is-narrow'>
              <div className='control'>
                <select name="coast" value={searchParams.get("coast") || "unknown"} data-testid={"coast-select"} onChange={(e) => handleFilterChanges(e)} className='select is-rounded'>
                  <option value="unknown">All</option>
                  {COASTS.map((coast) => {
                    return (
                      <option value={coast} key={coast}>{COASTS_TO_FULL_NAMES.get(coast)}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='field-label is-normal'>
            <label className='label'>Year</label>
          </div>
          <div className='field-body'>
            <div className='field is-narrow'>
              <div className='control'>
                <select name="year" value={searchParams.get("year") || "unknown"} data-testid={"year-select"} onChange={(e) => handleFilterChanges(e)} className='select is-rounded'>
                  <option value="unknown">All{PREVIEW_YEAR !== LATEST_YEAR_FOR_HOUSES && " (excluding preview year)"}</option>
                  {range(1991, Math.max(LATEST_YEAR_FOR_HOUSES, PREVIEW_YEAR)).reverse().map((year) => {
                    return (
                      <option value={year} key={year}>{year}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='field-label is-normal'>
            <label className='label'>IP/Original</label>
          </div>
          <div className='field-body'>
            <div className='field is-narrow'>
              <div className='control'>
                <select name="ip" value={searchParams.get("ip") || "unknown"} data-testid={"ip-select"} onChange={(e) => handleFilterChanges(e)} className='select is-rounded'>
                  <option value="unknown">All</option>
                  <option value="true">IP</option>
                  <option value="false">Original</option>
                </select>
              </div>
            </div>
          </div>
          <div className='field-label is-normal'>
            <label className='label'>Venue</label>
          </div>
          <div className='field-body'>
            <div className='field is-narrow'>
              <div className='control'>
                <select name="venue" value={searchParams.get("venue") || "unknown"} data-testid={"venue-select"} onChange={(e) => handleFilterChanges(e)} className='select is-rounded'>
                  <option value="unknown">All</option>
                  {Array.from(VENUE_NAMES_TO_IDS.keys()).filter((venue_name) => {
                    if (!searchParams.get("coast")) {
                      // If no coast is selected, get all of them.
                      return true;
                    } else {
                      return VENUE_NAMES_TO_IDS.get(venue_name)[1] === searchParams.get("coast");
                    }
                  }).map((venue_name) => {
                    return (
                      <option value={VENUE_NAMES_TO_IDS.get(venue_name)[0]} key={venue_name}>{venue_name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='field-label is-normal'>
            <label className='label'>Sort By</label>
          </div>
          <div className='field-body'>
            <div className='field is-narrow'>
              <div className='control'>
                <select name="sort" value={searchParams.get("sort") || "desc"} data-testid={"sort-select"} onChange={(e) => handleFilterChanges(e)} className='select is-rounded'>
                  <option value="desc">{DESC_SORT_ORDER_TITLE}</option>
                  <option value="asc">{ASC_SORT_ORDER_TITLE}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {loading && <div className='is-size-3 has-text-centered'>
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
          <br />
          Loading...
        </div>}
        {!loading && houses.length > 0 && <div>
          {houses.map((house, i) => {
            return <HouseListItem house={house} key={house["id"]} index={i} goToConfig={goToConfig} shouldShowOverall={filtersEmpty} page={page} shouldShowRankNumber={true} />
          })}
        </div>}
        {!loading && houses.length === 0 && <div>
          <h1>No Houses for This Set of Filters. Try Again!</h1>
        </div>}
        <div className='is-flex is-justify-content-center'>
          {!loading && count > 0 && page > 1 && <button type='button' className='button is-info m-3' onClick={(e) => goToPage(e, page - 1)}>Previous Page</button>}
          {!loading && count > page * DEFAULT_NUM_PER_PAGE && <button type='button' className='button is-info m-3' onClick={(e) => goToPage(e, page + 1)}>Next Page</button>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Rankings;
