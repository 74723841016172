// Mac refers to localhost as 127.0.0.1 so if the platform is a Mac,
// use that instead. It's a deprecated API but who cares as long since

import { Link } from "react-router-dom";

// this is a dev thing.
export const DEV_BACKEND_URL = window.navigator.platform.indexOf('Mac') !== -1 ?
    "http://127.0.0.1:5000/" :
    "http://localhost:5000/";

export const PROD_BACKEND_URL = "https://api.hhnh2h.com/";

// This is the last year with houses. If no houses has been announced for a 
// year yet, it will be the prior event but if there has been announcements
// it will be the next event year.
export const LATEST_YEAR_FOR_HOUSES = 2024;

export const EARLIEST_YEAR = 1991;

// Whether we should show the preview page .
// This should be false until we see 
export const ENABLE_PREVIEW = true;

// This is the latest year that isn't fully announced. We'll flip this to 
// LATEST_YEAR_FOR_HOUSES as soon as employee preview starts.
export const PREVIEW_YEAR = 2024;

export const COASTS = ["o", "h", "s", "j"];

export const YEARS = [EARLIEST_YEAR, LATEST_YEAR_FOR_HOUSES];

export const IP_VALS = ["true", "false"];

export const COASTS_TO_FULL_NAMES = new Map(
    [
        ["o", "Orlando"],
        ["h", "Hollywood"],
        ["s", "Singapore"],
        ["j", "Japan"]
    ]
);

export const YEAR_NICKNAMES = ["earlyYears", "iconYears", "ipYears", "modernYears"];

export const YEAR_NICKNAMES_TO_YEARS = new Map(
    [
        ["earlyYears", "1991-1999"],
        ["iconYears", "2000-2011"],
        ["ipYears", "2012-2019"],
        ["modernYears", "2020-2024"]
    ]
);

export const YEAR_NICKNAMES_TO_YEARS_PARSED = new Map(
    [
        ["earlyYears", [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]],
        ["iconYears", [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011]],
        ["ipYears", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019]],
        ["modernYears", [2020, 2021, 2022, 2023, 2024]]
    ]
);

// These represent the different combinations of the fully spelled out year ranges.
const e = (1999 - 1991) + 1; // early years
const i = (2011 - 2000) + 1; // icon years
const p = (2019 - 2012) + 1; // ip years
const m = (LATEST_YEAR_FOR_HOUSES - 2020) + 1; // modern years
export const YEAR_COMBOS = [
    e,
    i,
    p,
    m,
    e + i,
    e + p,
    e + m,
    i + p,
    i + m,
    p + m,
    e + i + p,
    e + i + m,
    i + p + m,
    e + p + m,
    e + i + p + m
];

export const YEAR_NICKNAMES_TO_TITLES = new Map(
    [
        ["earlyYears", "Early Years (1991-1999)"],
        ["iconYears", "Icon Years (2000-2011)"],
        ["ipYears", "IP Years (2012-2019)"],
        ["modernYears", "Modern Years (2020-Present)"]
    ]
);

export const COAST_NAMES = ["o", "h", "s", "j"];

export const COAST_ENUM_TO_NAME = new Map(
    [
        [1, "Unknown"],
        [2, "Orlando"],
        [3, "Hollywood"],
        [4, "Singapore"],
        [5, "Japan"]
    ]
);

export const COAST_ENUM_TO_COAST = new Map(
    [
        [1, "?"],
        [2, "o"],
        [3, "h"],
        [4, "s"],
        [5, "j"]
    ]
);

export const DEFAULT_YEARS = ["2012-2019", "2020-2024"];

export const DEFAULT_COAST = ["o"];

export const SWITCH_TO_ADVANCED_TITLE = 'Switch to advanced mode to edit year ranges.';

export const ONE_YEAR_RANGE_TITLE = "At least one year range needs to be checked.";

export const ONE_COAST_TITLE = "At least one coast needs to be checked.";

export const DESC_SORT_ORDER_TITLE = "Highest Score First";

export const ASC_SORT_ORDER_TITLE = "Lowest Score First";

export const VENUE_NAMES_TO_IDS = new Map(
    [
        ["747 Area - (Hollywood)", [5, "h"]],
        ["Backdraft - (Japan)", [49, "j"]],
        ["Bates Motel - (Orlando)", [6, "o"]],
        ["Behind Soundstage 29 - (Hollywood)", [70, "h"]],
        ["Between Hollywood and New York - (Singapore)", [55, "s"]],
        ["Between Jurassic Park and Ancient Egypt - (Singapore)", [56, "s"]],
        ["Between Sci-Fi City and Egypt - (Singapore)", [45, "s"]],
        ["Carnage Warehouse - (Orlando)", [7, "o"]],
        ["Curious George Tent 1 - (Hollywood)", [68, "h"]],
        ["Curious George Tent 2 - (Hollywood)", [69, "h"]],
        ["Disaster! Queue - (Orlando)", [8, "o"]],
        ["Dispicable Me Minion Mayhem - (Japan)", [48, "j"]],
        ["ET Adventure - (Orlando)", [10, "o"]],
        ["Earthquake Queue - (Orlando)", [9, "o"]],
        ["FDTD Tent - (Hollywood)", [12, "h"]],
        ["Fast & Furious Venue - (Orlando)", [11, "o"]],
        ["Jaws Queue - (Orlando)", [13, "o"]],
        ["Jurassic Park Discovery Center - (Orlando)", [14, "o"]],
        ["Jurassic Park Queue - (Hollywood)", [66, "h"]],
        ["Jurassic Park Tent - (Singapore)", [43, "s"]],
        ["MIB Tent - (Orlando)", [16, "o"]],
        ["Metro Sets - (Hollywood)", [15, "h"]],
        ["Mummy Queue - (Hollywood)", [17, "h"]],
        ["Nazarman's - (Orlando)", [18, "o"]],
        ["New Parade Warehouse - (Orlando)", [23, "o"]],
        ["New York Stage - (Singapore)", [57, "s"]],
        ["Old Parade Warehouse - (Orlando)", [19, "o"]],
        ["Outside Soundstage 28 - (Singapore)", [42, "s"]],
        ["Palace Theater - (Japan)", [52, "j"]],
        ["Parisian Courtyard - (Hollywood)", [20, "h"]],
        ["Popeye and Bluto's Bilge-Rat Barges Queue - (Orlando)", [21, "o"]],
        ["Poseidon's Fury - (Orlando)", [22, "o"]],
        ["Rockefeller Center Tent - (Singapore)", [44, "s"]],
        ["San Francisco - (Japan)", [47, "j"]],
        ["Shrek 4D Queue - (Hollywood)", [64, "h"]],
        ["Shrek Theater - (Orlando)", [24, "o"]],
        ["Soundstage 12 - (Hollywood)", [75, "h"]],
        ["Soundstage 18 - (Orlando)", [25, "o"]],
        ["Soundstage 18 - (Japan)", [51, "j"]],
        ["Soundstage 19 - (Orlando)", [26, "o"]],
        ["Soundstage 20 - (Orlando)", [27, "o"]],
        ["Soundstage 21 - (Orlando)", [28, "o"]],
        ["Soundstage 22 - (Orlando)", [2, "o"]],
        ["Soundstage 22 - (Japan)", [54, "j"]],
        ["Soundstage 22 - (Hollywood)", [71, "h"]],
        ["Soundstage 23 - (Orlando)", [3, "o"]],
        ["Soundstage 24 - (Orlando)", [29, "o"]],
        ["Soundstage 25 - (Orlando)", [30, "o"]],
        ["Soundstage 28 - (Singapore)", [31, "s"]],
        ["Soundstage 28 Area - (Hollywood)", [67, "h"]],
        ["Soundstage 29 - (Hollywood)", [32, "h"]],
        ["Soundstage 34 - (Hollywood)", [62, "h"]],
        ["Soundstage 36 - (Hollywood)", [60, "h"]],
        ["Soundstage 44 - (Orlando)", [33, "o"]],
        ["Space Fantasy - (Japan)", [50, "j"]],
        ["Sprung Tent 1 - (Orlando)", [34, "o"]],
        ["Sprung Tent 2 - (Orlando)", [35, "o"]],
        ["Sprung Tent 3 - (Orlando)", [76, "o"]],
        ["Sprung Tent 4 - (Orlando)", [77, "o"]],
        ["Sting Alley - (Singapore)", [36, "s"]],
        ["Terminator Queue - (Hollywood)", [65, "h"]],
        ["Terror Tram - (Hollywood)", [59, "h"]],
        ["The Boneyard - (Orlando)", [37, "o"]],
        ["The Obelisk - (Japan)", [53, "j"]],
        ["Thunderfalls Terrace - (Orlando)", [38, "o"]],
        ["Tram Warehouse - (Hollywood)", [61, "h"]],
        ["Triceratops Discovery Trail - (Orlando)", [39, "o"]],
        ["Uncertain Location (USJ) - (Japan)", [46, "j"]],
        ["Uncertain Lower Lot Location (USH) - (Hollywood)", [63, "h"]],
        ["Uncertain Upper Lot Location (USH) - (Hollywood)", [58, "h"]],
        ["Uncertain Location (USF) - (Orlando)", [72, "o"]],
        ["Uncertain Location (USH) - (Hollywood)", [73, "h"]],
        ["Uncertain Location (USS) - (Singapore)", [74, "s"]],
        ["Waterworld Queue - (Singapore)", [1, "s"]],
        ["Waterworld Queue - (Hollywood)", [4, "h"]],
        ["Wild Wild West Stage - (Hollywood)", [40, "h"]],
        ["Year Round Haunt Venue - (Hollywood)", [41, "h"]],
    ]
);

export const VENUE_ID_TO_NAMES = new Map(
    [
        [5, "747 Area - (Hollywood)"],
        [49, "Backdraft - (Japan)"],
        [6, "Bates Motel - (Orlando)"],
        [70, "Behind Soundstage 29 - (Hollywood)"],
        [55, "Between Hollywood and New York - (Singapore)"],
        [56, "Between Jurassic Park and Ancient Egypt - (Singapore)"],
        [45, "Between Sci-Fi City and Egypt - (Singapore)"],
        [7, "Carnage Warehouse - (Orlando)"],
        [68, "Curious George Tent 1 - (Hollywood)"],
        [69, "Curious George Tent 2 - (Hollywood)"],
        [8, "Disaster! Queue - (Orlando)"],
        [48, "Dispicable Me Minion Mayhem - (Japan)"],
        [10, "ET Adventure - (Orlando)"],
        [9, "Earthquake Queue - (Orlando)"],
        [12, "FDTD Tent - (Hollywood)"],
        [11, "Fast & Furious Venue - (Orlando)"],
        [13, "Jaws Queue - (Orlando)"],
        [14, "Jurassic Park Discovery Center - (Orlando)"],
        [66, "Jurassic Park Queue - (Hollywood)"],
        [43, "Jurassic Park Tent - (Singapore)"],
        [16, "MIB Tent - (Orlando)"],
        [15, "Metro Sets - (Hollywood)"],
        [17, "Mummy Queue - (Hollywood)"],
        [18, "Nazarman's - (Orlando)"],
        [23, "New Parade Warehouse - (Orlando)"],
        [57, "New York Stage - (Singapore)"],
        [19, "Old Parade Warehouse - (Orlando)"],
        [42, "Outside Soundstage 28 - (Singapore)"],
        [52, "Palace Theater - (Japan)"],
        [20, "Parisian Courtyard - (Hollywood)"],
        [21, "Popeye and Bluto's Bilge-Rat Barges Queue - (Orlando)"],
        [22, "Poseidon's Fury - (Orlando)"],
        [44, "Rockefeller Center Tent - (Singapore)"],
        [47, "San Francisco - (Japan)"],
        [64, "Shrek 4D Queue - (Hollywood)"],
        [24, "Shrek Theater - (Orlando)"],
        [75, "Soundstage 12 - (Hollywood)"],
        [25, "Soundstage 18 - (Orlando)"],
        [51, "Soundstage 18 - (Japan)"],
        [26, "Soundstage 19 - (Orlando)"],
        [27, "Soundstage 20 - (Orlando)"],
        [28, "Soundstage 21 - (Orlando)"],
        [2, "Soundstage 22 - (Orlando)"],
        [54, "Soundstage 22 - (Japan)"],
        [71, "Soundstage 22 - (Hollywood)"],
        [3, "Soundstage 23 - (Orlando)"],
        [29, "Soundstage 24 - (Orlando)"],
        [30, "Soundstage 25 - (Orlando)"],
        [31, "Soundstage 28 - (Singapore)"],
        [67, "Soundstage 28 Area - (Hollywood)"],
        [32, "Soundstage 29 - (Hollywood)"],
        [62, "Soundstage 34 - (Hollywood)"],
        [60, "Soundstage 36 - (Hollywood)"],
        [33, "Soundstage 44 - (Orlando)"],
        [50, "Space Fantasy - (Japan)"],
        [34, "Sprung Tent 1 - (Orlando)"],
        [35, "Sprung Tent 2 - (Orlando)"],
        [76, "Sprung Tent 3 - (Orlando)"],
        [77, "Sprung Tent 4 - (Orlando)"],
        [36, "Sting Alley - (Singapore)"],
        [65, "Terminator Queue - (Hollywood)"],
        [59, "Terror Tram - (Hollywood)"],
        [37, "The Boneyard - (Orlando)"],
        [53, "The Obelisk - (Japan)"],
        [38, "Thunderfalls Terrace - (Orlando)"],
        [61, "Tram Warehouse - (Hollywood)"],
        [39, "Triceratops Discovery Trail - (Orlando)"],
        [46, "Uncertain Location (USJ) - (Japan)"],
        [63, "Uncertain Lower Lot Location (USH) - (Hollywood)"],
        [58, "Uncertain Upper Lot Location (USH) - (Hollywood)"],
        [72, "Uncertain Location (USF) - (Orlando)"],
        [73, "Uncertain Location (USH) - (Hollywood)"],
        [74, "Uncertain Location (USS) - (Singapore)"],
        [1, "Waterworld Queue - (Singapore)"],
        [4, "Waterworld Queue - (Hollywood)"],
        [40, "Wild Wild West Stage - (Hollywood)"],
        [41, "Year Round Haunt Venue - (Hollywood)"],
    ]
);

export const DEFAULT_NUM_PER_PAGE = 20;

export const FLASH_MESSAGE = "HHN 2024 is officially complete! Search for your favorite house to leave a review or vote on your favorites!";

export const FLASH_MARKUP = (
    <span>HHN 2024 is officially complete! <Link to="/search">Search</Link> for your favorite house to leave a review or <Link to="/?year=2024">vote</Link> on your favorites!</span>
);

export const REVIEW_PLACEHOLDER_TEXT = "Leave your review here! And don't forget to check the box if you did experience this house!";