import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { convertEloTo100Ranking, getBackendUrl } from './utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Legend, Tooltip, Colors } from 'chart.js';
import BlurryLoadingImage from './components/BlurryLoadingImage';
import { COAST_ENUM_TO_COAST, COAST_ENUM_TO_NAME } from './utils/constants';
import SEO from './components/SEO';
import Reviews from './components/Reviews';

import styles from './HouseDetail.module.scss';
import Nav from './components/Nav';

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Legend, Tooltip, Colors);

/**
 * This is the HouseDetails component to show individual house details.
 * 
 * @returns The HouseDetails component.
 */
function HouseDetails() {
    const [loading, setLoading] = useState(false);
    const [house, setHouse] = useState({});
    const [rank, setRank] = useState("overall");
    const { houseId } = useParams();

    const getTitleFromRank = () => {
        if (rank === "overall") {
            return "Overall Rank";
        }
        if (rank === "coast") {
            return "Coast Rank";
        }
        if (rank === "year") {
            return "Year Rank";
        }
        if (rank === "venue") {
            return "Venue Rank";
        }
        if (rank === "ip") {
            return "IP/Original Rank"
        }

        return "Rank";
    }

    const getHouse = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        const response = await fetch(getBackendUrl() + 'house/' + houseId);
        if (response.status === 404) {
            // Handle the 404 here.
            setLoading(false);
            return;
        }

        const data = await response.json();
        setHouse(data);
        setLoading(false);
    };

    useEffect(() => {
        getHouse();
    }, [houseId]);

    // This function handles two things:
    // 1. Ranks come in zero indexed so make them 1 indexed.
    // 2. If a house does not have enough rankings, it'll be displayed
    // as -1 and instead we want it as "not enough votes"
    const renderRank = (rank) => {
        if (rank < 0) {
            return "Not Enough Votes";
        }

        return '#' + (rank + 1);
    }

    // It's better if we give the right page for the rank
    const getPageForRank = (rank, firstfilter) => {
        const adjustedRank = rank + 1;
        // This is the index 0 rank so if it's a -1 or < 19 (aka #20), there
        // shouldn't be any page appended.
        if (adjustedRank <= 20) {
            return "";
        }

        return (firstfilter ? "?" : "&") + "page=" + Math.ceil(adjustedRank / 20);
    };

    let imageURL = 'https://picsum.photos/640/280';
    let previewImageURL = 'https://picsum.photos/64/28';
    if (house["image_url"]) {
        imageURL = getBackendUrl() +
            "static/facades/" +
            house["event"]["year"] +
            "/" + COAST_ENUM_TO_COAST.get(house["event"]["coast"]) +
            "/" + house["image_url"];
        const low_res_name = house["image_url"].replace(".jpg", "_low.jpg");
        previewImageURL = getBackendUrl() +
            "static/facades/" +
            house["event"]["year"] +
            "/" + COAST_ENUM_TO_COAST.get(house["event"]["coast"]) +
            "/" + low_res_name;
    }

    const houseScore = convertEloTo100Ranking(house["score"]);
    let houseScoreColor = '';
    if (houseScore < 26) {
        houseScoreColor = 'has-background-danger';
    } else if (houseScore < 51) {
        houseScoreColor = 'has-background-warning';
    } else if (houseScore < 76) {
        houseScoreColor = 'has-background-success';
    } else {
        houseScoreColor = 'has-background-info';
    }
    let houseScoreText = 'has-text-light';
    if (houseScore > 25 && houseScore < 51) {
        houseScoreText = 'has-text-dark';
    }

    let chartData = [];
    if (house["score_history"]) {
        chartData = [...house["score_history"]].reverse();
    }

    let preview = false;
    if (house["preview_until"]) {
        preview = Date.parse(house["preview_until"]) > Date.now();
        if (preview && rank !== "year") {
            setRank("year");
        }
    }

    return (
        <div className={styles.body}>
            <Nav />
            <SEO
                title={!loading && Object.keys(house).length > 0 ? (house["name"] + ' - Summary, Details and Rankings | Halloween Horror Nights | HHN Head2Head') : "Loading..."}
                description={(!loading && Object.keys(house).length > 0 && house["event"]) ? "Learn more about the " + house["name"] + " haunted house at " + house["event"]["name"]
                    + ". Learn about the house itself, reviews and where it ranks in the list of HHN houses. Learn more about your favorite haunted houses here!" : "Loading..."
                }
            />
            <div>
                {loading && <div className='is-size-3 has-text-centered'>
                    <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
                    <br />
                    Loading...
                </div>}
                {!loading && Object.keys(house).length > 0 && <div className='columns mt-3'>
                    <div className='column'>
                        <div className={styles.houseimage}>
                            <BlurryLoadingImage preview={previewImageURL} image={imageURL} alt={house["name"]} width='640' height='280' imageStyleClass={""} divStyleClass={""} />
                            <div className={styles.score + ' ' + houseScoreColor}>
                                <span className={houseScoreText}>{houseScore / 10}</span>
                                <span className={styles.scorefiller}> / 10</span>
                            </div>
                        </div>
                        <div className='is-hidden-touch'>
                            <h2 className={'my-6 title is-4 ' + styles.titlewithline}><span>Description</span></h2>
                            <div className='px-6'>{house["description"]}</div>
                        </div>
                        <div className='is-hidden-touch'>
                            <h2 className={'my-6 title is-4 ' + styles.titlewithline}><span>Reviews</span></h2>
                            <Reviews data={house["reviews"]} house_id={house["id"]} />
                        </div>
                        <h1 className='title is-1 my-2 has-text-centered is-hidden-desktop'>{house["name"]}</h1>
                    </div>
                    <div className='column'>
                        <h1 className='title is-1 my-2 has-text-centered is-hidden-touch'>{house["name"]}</h1>
                        <div className='is-hidden-desktop'>
                            <h2 className={'mt-1 mb-6 title is-4 ' + styles.titlewithline}><span>Description</span></h2>
                            <div className='px-6'>{house["description"]}</div>
                        </div>
                        <h2 className={'my-6 title is-4 ' + styles.titlewithline}><span>Details</span></h2>
                        <div className={'' + styles.detailscontainer}>
                            <div className={styles.detailsbox + ' ' + styles.blue}>
                                <span className={styles.detailsvalue}>{COAST_ENUM_TO_NAME.get(house["event"]["coast"])}</span>
                                <span className={styles.detailslabel}>Coast</span>
                            </div>
                            <div className={styles.detailsbox + ' ' + styles.red}>
                                <span className={styles.detailsvalue}>{house["event"]["year"]}</span>
                                <span className={styles.detailslabel}>Year</span>
                            </div>
                            <div className={styles.detailsbox + ' ' + styles.yellow}>
                                <span className={styles.detailsvalue}>{house["venue"]["name"]}</span>
                                <span className={styles.detailslabel}>Venue</span>
                            </div>
                            <div className={styles.detailsbox + ' ' + styles.green}>
                                <span className={styles.detailsvalue}>{house["ip"] ? "IP" : "Original"}</span>
                                <span className={styles.detailslabel}>IP/Original</span>
                            </div>
                        </div>
                        <h2 className={'my-6 title is-4 ' + styles.titlewithline}><span>Ranks</span></h2>
                        {preview && <div className='mx-3 my-1'>
                            This house is in preview and isn't available to vote against other houses. You can only see year data for this house.
                        </div>}
                        <div className={styles.detailscontainer}>
                            {!preview && <Link className={styles.detailsbox + ' ' + styles.white} to={"/rankings" + getPageForRank(house["ranks"]["overall"], true)} data-testid="overallLink">
                                <span className={styles.detailsvalue}>{renderRank(house["ranks"]["overall"])}</span>
                                <span className={styles.detailslabel}>Overall</span>
                            </Link>}
                            {!preview && <Link className={styles.detailsbox + ' ' + styles.blue} to={"/rankings?coast=" + COAST_ENUM_TO_COAST.get(house["event"]["coast"], false) + getPageForRank(house["ranks"]["coast"])} data-testid="coastLink">
                                <span className={styles.detailsvalue}>{renderRank(house["ranks"]["coast"])}</span>
                                <span className={styles.detailslabel}>Coast</span>
                            </Link>}
                            <Link className={styles.detailsbox + ' ' + styles.red} to={"/rankings?year=" + house["event"]["year"] + getPageForRank(house["ranks"]["year"], false)} data-testid="yearLink">
                                <span className={styles.detailsvalue}>{renderRank(house["ranks"]["year"])}</span>
                                <span className={styles.detailslabel}>Year</span>
                            </Link>
                            {!preview && <Link className={styles.detailsbox + ' ' + styles.yellow} to={"/rankings?venue=" + house["venue"]["id"] + getPageForRank(house["ranks"]["venue"], false)} data-testid="venueLink">
                                <span className={styles.detailsvalue}>{renderRank(house["ranks"]["venue"])}</span>
                                <span className={styles.detailslabel}>Venue</span>
                            </Link>}
                            {!preview && <Link className={styles.detailsbox + ' ' + styles.green} to={"/rankings?ip=" + (house["ip"] ? "true" : "false") + getPageForRank(house["ranks"]["ip"], false)} data-testid="ipLink">
                                <span className={styles.detailsvalue}>{renderRank(house["ranks"]["ip"])}</span>
                                <span className={styles.detailslabel}>IP/Original</span>
                            </Link>}
                        </div>
                        {chartData.length > 0 && <>
                            <h2 className={'my-6 title is-4 ' + styles.titlewithline}><span>Ranking Over Time</span></h2>
                            <div className={'field-body ' + styles.controlmargin}>
                                <div className='field is-narrow'>
                                    <div className='control'>
                                        <select name="rankType" value={rank} onChange={(e) => setRank(e.target.value)} className='select is-rounded'>
                                            {!preview && <option value="overall">Overall Rank</option>}
                                            {!preview && <option value="coast">Coast Rank</option>}
                                            <option value="year">Year Rank</option>
                                            {!preview && <option value="venue">Venue Rank</option>}
                                            {!preview && <option value="ip">IP/Original Rank</option>}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <Line
                                className={styles.graphmargin}
                                data={{
                                    datasets: [{
                                        label: getTitleFromRank(),
                                        data: chartData.map(sh => {
                                            return {
                                                x: sh["date"].substring(0, 10),
                                                y: (sh["rank_" + rank] + 1)
                                            }
                                        })
                                    }]
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        x: {
                                            min: chartData[0]["date"].substring(0, 10)
                                        },
                                        y: {
                                            reverse: true,
                                            ticks: { precision: 0 },
                                            min: 1
                                        }
                                    }
                                }}
                            />
                        </>}
                        <div className='is-hidden-desktop'>
                            <h2 className={'my-6 title is-4 ' + styles.titlewithline}><span>Reviews</span></h2>
                            <Reviews data={house["reviews"]} house_id={house["id"]} />
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default HouseDetails;